/* eslint-disable no-useless-escape */
export const validEmailPattern =
  /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;

export const specialCharRegex = /[ `!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/? ~]/;

export const upperCaseRegex = /[A-Z]/;

export const lowerCaseRegex = /[a-z]/;

export const alphaNumericRegex = /^[A-Za-z0-9\s]+$/;

export const alphanumWithUnderscoreAndHyphen =  /^[a-zA-Z0-9 _-]*$/;
