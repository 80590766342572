import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  Checkbox,
  Typography,
  Link,
  Button,
  Iconography,
} from "@hid-galaxy-ui/galaxy-react";
import { useDispatch, useSelector } from "react-redux";
import { IconographyThemes } from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import CenteredLayout from "../../../widgets/CenteredLayout";
import { changeOrganizationPath, homePath } from "../../../utils/routes";
import { getCommonNS, NAMESPACE } from "../../../utils/i18nUtils";
import {
  acceptTermsofService,
  acceptTosPayload,
  termsOfService,
} from "../../../services/loginAPIService";
import { loginInfoData } from "../../../reducers/userInfoReducer";

interface IStatus {
  code: number;
  description: string;
}
export interface ITOSResponse {
  data: {
    tos: string;
    tosVersion: number;
  };
  status: IStatus;
}
export default function TermsOfService() {
  const { selectedCustomerInfo, userInfo, linq_enabled_orgs } =
    useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();

  const [checked, setChecked] = useState<boolean>(false);
  const [tosResponse, setTosResponse] = useState<ITOSResponse>();
  const { t } = useTranslation(NAMESPACE.APP);
  const navigate = useNavigate();
  useEffect(() => {
    termsOfService().then((response: any) => {
      setTosResponse(response);
    });
  }, []);
  const handleOnContinue = () => {
    const payload = {
      email: userInfo?.email,
      version: tosResponse?.data?.tosVersion,
    };
  window.localStorage.setItem("acceptedTOS", JSON.stringify({acceptedTos: checked}));
    acceptTermsofService(payload as acceptTosPayload)
      .then((response) => {
        if (response) {
          linq_enabled_orgs.length > 1
            ?  navigate(homePath)
            : navigate(changeOrganizationPath);
        }
      })
      .catch((error) => {
        console.log(error.response.data.statusDescription);
      });
  };
  return (
    <>
      <CenteredLayout>
        <Card className="hid-flex hid-flex-jc-center hid-flex-ai-center hid-origo__tos-container">
          <div
            className="hid-layout__pt-04 hid-layout__pb-04 hid-origo__text-layout"
            data-testid="terms-of-service"
          >
            <Typography
              variant={TypographyVariantEnum.H1}
              className="hid-text-center"
            >
              {t("TERMS_OF_SERVICE.WELCOME")}
            </Typography>
            <Typography
              variant={TypographyVariantEnum.H3}
              className="hid-layout__mt-02 hid-text-center "
            >
              {t("TERMS_OF_SERVICE.TERMS")}
            </Typography>
            <Typography
              variant={TypographyVariantEnum.TextHelper}
              className="hid-layout__mt-04"
            >
              {t("TERMS_OF_SERVICE.GLOBAL_CORPORATION")}
            </Typography>
            {tosResponse && tosResponse.data && (
              <div className="hid-origo__terms-scrollable-box hid-layout__mt-01">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tosResponse?.data?.tos,
                  }}
                ></div>
              </div>
            )}
            <div className="hid-flex hid-layout__mt-04">
              <Checkbox
                checked={checked}
                value="checked"
                onChange={() => setChecked(!checked)}
              >
                <div>
                  {t("TERMS_OF_SERVICE.READ_AND_UNDERSTAND")}
                  <span>{t("TERMS_OF_SERVICE.GLOBAL_CORPORATION")}</span>  
                </div>
              </Checkbox>
            </div>
            <div className="hid-flex hid-flex-jc-center hid-layout__pb-04">
              <Button
                type="button"
                label={t("COMMON.CONTINUE", getCommonNS())}
                disabled={!checked}
                className="hid-origo__button"
                onClick={() => handleOnContinue()}
              />
            </div>
          </div>
        </Card>
      </CenteredLayout>
    </>
  );
}
